<template>
  <Popup ref="popupRef" title="订单明细" width="420px" :show-cancel="false"
  confirm-text="关闭明细" @confirm="close()">
    <div v-if="detail">
      <div class="row" v-if="detail.title">
        <div class="label">交易类型：</div>
        <div class="input">{{detail.title}}</div>
      </div>
      <div class="row" v-if="detail.amountDesc">
        <div class="label">交易金额：</div>
        <div class="input red-text">{{detail.amountDesc}}</div>
      </div>
      <div class="row" v-if="detail.orderStatusDesc">
        <div class="label">交易状态：</div>
        <div class="input">{{detail.orderStatusDesc}}</div>
      </div>
      <div class="row" v-if="detail.orderId">
        <div class="label">订单编号：</div>
        <div class="input">{{detail.orderId}}</div>
      </div>
      <div class="row" v-if="detail.submitTime">
        <div class="label">申请时间：</div>
        <div class="input">{{detail.submitTime}}</div>
      </div>
      <div class="row" v-if="payMethod">
        <div class="label">支付方式：</div>
        <div class="input">{{payMethod}}</div>
      </div>
      <div class="row" v-if="detail.payTime">
        <div class="label">到账时间：</div>
        <div class="input">{{detail.payTime}}</div>
      </div>
      <div class="row" v-if="detail.failReason">
        <div class="label">失败原因：</div>
        <div class="input">{{detail.failReason}}</div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
export default {
  components: {
    Popup
  },
  data() {
    return {
      detail: null
    }
  },
  computed: {
    payMethod() {
      if(!this.detail) return ''
      const map = {
        'ALIPAY': '支付宝',
        'WX': '微信'
      }
      return map[this.detail.payMethod] || ''
    }
  },
  methods: {
    open(info) {
      this.detail = info
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    }
  }
}
</script>

<style lang="scss" scoped>

.row {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 24px;
  .label {
    width: 84px;
    color: #65666F;
  }
}
.input {
  flex: 1;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid #E8E8E8;
}
</style>