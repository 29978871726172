<template>
  <Popup ref="popupRef" title="充值任务币" width="530px" :showConfirm="false" :showCancel="false" @close="resetData">
    <div class="recharge-tips">
      <img src="@/assets/images/icon-ling.png">
      <div class="flex-1">原价20/份，长期有效，选择套餐可享受折扣优惠。更多套餐详情、企业采购请咨询客服</div>
      <div class="btn-contact" @click="toCustomer">咨询客服</div>
    </div>
    <div class="label mt24">充值金额：</div>
    <el-input placeholder="请输入金额" v-model="form.money">
      <template #suffix>
        <div style="line-height: 40px">元</div>
      </template>
    </el-input>
    <div class="money-list">
      <div @click="form.money = 50">50元</div>
      <div @click="form.money = 200">200元</div>
      <div @click="form.money = 500">500元</div>
      <div @click="form.money = 1000">1000元</div>
    </div>
    <div class="label mt24">支付方式：</div>
    <Payway :showBalance="false" @change="paywayChange"></Payway>
    <!-- 底部按钮 -->
    <div class="bottom-row">
      <div class="total">
        <div>合计 <span class="total-num">{{form.money || '0.00'}}</span>元 </div>
        <!-- <div class="tips">点击确认支付，代表你同意 <span class="policy">《自助购买协议》</span> </div> -->
      </div>
      <div class="btn-group">
        <div class="btn-cancel btn-border" @click="close()">取消</div>
        <div class="btn-confirm btn-fill-theme" @click="confirm()">确认支付</div>
      </div>
    </div>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import Payway from '@/components/Payway'
export default {
  components: {
    Popup,
    Payway
  },
  data() {
    return {
      form: {
        payMethod: '',
        money: ''
      }
    }
  },
  methods: {
    open() {
      this.$refs.popupRef.open()
    },
    close() {
      this.$refs.popupRef.close()
    },
    resetData() {
      this.form.money = ''
    },
    toCustomer() {
      this.$router.push({ name: 'Message', params: {isToCustomer: true}})
    },
    paywayChange(val) {
      this.form.payMethod = val
    },
    confirm() {
      return this.$tips({message: '当前功能不可用，请联系客服处理', type: 'warning'})
    }
  }
}
</script>

<style lang="scss" scoped>
.recharge-tips {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  color: #FF941A;
  border: 1px solid #FF941A;
  border-radius: 4px;
  background-color: #FFF2E7;
  padding: 8px 12px;
  .btn-contact {
    height: 26px;
    line-height: 26px;
    padding: 0 8px;
    border-radius: 13px;
    text-align: center;
    color: #FF941A;
    background-color: #fff;
    margin-left: 8px;
    cursor: pointer;
  }
  img{
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
.label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #65666F;
  margin-bottom: 16px;
}
.money-list {
  display: flex;
  align-items: center;
  margin-top: 8px;
  &>div {
    width: 90px;
    height: 30px;
    line-height: 30px;
    margin-right: 8px;
    font-size: 14px;
    border: 1px solid #E8E8E8;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
  }
}
.bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  .btn-group {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn-cancel, .btn-confirm {
      padding: 10px 24px;
      border-radius: 4px;
      font-size: 14px;
      margin-left: 12px;
      cursor: pointer;
    }
  }

  .total {
    font-size: 14px;
    color: var(--theme-color-red);
    .total-num {
      font-size: 24px;
      font-weight: bold;
    }
    .tips {
      font-size: 12px;
      color: #B0B3BF;
      margin-top: 8px;
    }
    .policy {
      color: var(--theme-color);
      cursor: pointer;
    }
  }
}
</style>