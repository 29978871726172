<template>
  <div>
    <div class="nav-title">我的任务币</div>
    <div class="panel flex items-center">
      <div class="balance">
        <span>任务币余额：</span>
        <span class="num">{{userInfo.taskCyBalance}}</span>
      </div>
      <div class="btn-recharge btn-fill-theme" @click="$refs.rechargePopupRef.open()">充值</div>
    </div>
    <div class="panel mt12">
      <div class="fs16 bold mb16">任务币账单明细</div>
      <div class="flex between-center mb16">
        <div class="flex items-center">
          <el-date-picker
            v-model="form.date"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
          <div class="btn-search btn-fill-theme" @click="getBillList">查找</div>
        </div>
        <el-dropdown @command="billCateChange">
          <div class="btn-dropdown">
            <span>{{billCateList[billCateIndex].text || ''}}</span>
            <img src="@/assets/images/arrow-down.png">
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in billCateList" :key="index"
            :command="index" >{{item.text}}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <!-- 账单列表 -->
      <el-table
        :data="billList" style="width: 100%" 
        :header-row-style="{'font-weight': 'bold','color': '#2A2A2A'}"
        :header-cell-style="{'background-color': '#F2F3F5'}">
        
        <el-table-column
          prop="createTime"
          label="创建时间"
          width="180">
        </el-table-column>
        <el-table-column
          prop="title"
          label="名称">
        </el-table-column>
        <el-table-column
          label="金额明细"
          width="130">
          <template slot-scope="scope">
            <span :class="{
            'red-text':scope.row.amount.includes('-'),
            'green-text': scope.row.amount.includes('+')
            }">{{scope.row.amount}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="statusDesc"
          label="状态"
          width="130">
        </el-table-column>
        <el-table-column
          label="操作"
          width="180">
          <template slot-scope="scope">
            <span class="btn-check" @click="checkDetail(scope.row)">查看明细</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="flex justify-end mt24">
        <Pagination :current-page="curPage + 1" @next-click="nextPage" @prev-click="prevPage"/>
      </div>
    </div>
    <!-- 账单详情 -->
    <BillDetailPopup ref="billDetailRef"></BillDetailPopup>
    <!-- 充值弹窗 -->
    <RechargePopup ref="rechargePopupRef"></RechargePopup>
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import BillDetailPopup from './components/BillDetailPopup'
import RechargePopup from './components/RechargePopup'
import { billsApi, orderDetailApi } from '@/api/user.js'
import { mapState } from 'vuex'
export default {
  name: "UserAsset",
  components: {
    Pagination,
    BillDetailPopup,
    RechargePopup
  },
  data() {
    return {
      billCateList: [
        {text: '全部明细', type: 0},
        {text: '充值明细', type: 1},
        {text: '提现明细', type: 2},
        {text: '赚钱明细', type: 3},
        {text: '消费明细', type: 4},
      ], // 0-全部 1-充值 2-提现 3-赚钱 4-消费
      billCateIndex: 0,
      billList: [],
      form: {
        date: ''
      },
      curPage: 0
    }
  },
  computed: {
    ...mapState(['userInfo'])
  },
  created() {
    this.getBillList()
  },
  methods: {
    getBillList() {
      billsApi({
        startPage: this.curPage,
        type: this.billCateList[this.billCateIndex].type,
        startTime: this.form.date[0] || '',
        endTime: this.form.date[1] || ''
      }).then(res => {
        console.log('账单详情', res)
        const list = res.data.bills || []
        if(list.length > 0 || this.curPage == 0) {
          this.billList = list
        } else {
          this.$tips({type: 'warning', message: '没有更多数据了'})
          this.curPage--
        }
      }).catch((err) => {
        this.$tips({ message: err, type: "error" });
      })
    },
    toSearch() {

    },
    billCateChange(index) {
      this.billCateIndex = index
      this.curPage = 0
      this.getBillList()
    },
    nextPage() {
      this.curPage++
      this.getBillList()
    },
    prevPage() {
      this.curPage = this.curPage > 0 ? this.curPage - 1 : 0
      this.getBillList()
    },
    checkDetail(detailInfo) {
      orderDetailApi({orderId: detailInfo.orderId}).then(res => {
        console.log('订单详情', res)
        this.$refs.billDetailRef.open({
          createTime: detailInfo.createTime,
          ...res.data
        })
      }).catch((err) => {
        this.$tips({ message: err, type: "error" });
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dropdown-menu__item {
  width: 130px;
  padding: 0 12px;
}
.nav-title {
  width: 96px;
  font-size: 16px;
  color: var(--theme-color);
  padding-bottom: 16px;
  border-bottom: 3px solid var(--theme-color);
  text-align: center;
}
.balance {
  display: flex;
  align-items: center;
  .num {
    font-size: 36px;
    font-weight: bold;
    color: var(--theme-color-red);
  }
}
.btn-recharge {
  width: 84px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  text-align: center;
  margin-left: 100px;
  font-size: 16px;
  cursor: pointer;
}
.btn-search {
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
  border-radius: 4px;
  font-size: 14px;
  margin-left: 16px;
  cursor: pointer;
}
.btn-dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 130px;
  height: 40px;
  line-height: 40px;
  padding: 0 12px;
  border-radius: 4px;
  border: 1px solid #E8E8E8;
  font-size: 14px;
  cursor: pointer;
  img {
    width: 16px;
    height: 16px;
  }
}
.btn-check {
  color: var(--theme-color);
  cursor: pointer;
}

</style>